// i18next-extract-mark-ns-start blog

import {SEO} from 'components/SEO';
import {graphql, PageProps} from 'gatsby';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import BlogLayout from 'layouts/BlogLayout';
import {colors} from 'global.config';
import {RichText} from 'prismic-reactjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import {serializeHyperlink} from 'utils/serializeHyperlink';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {BlogCategoryType, BlogLocale, BlogPost} from '../../types/BlogTypes';
import {
  getBlogCaption,
  getPostsFromPrismicEdges,
  mapCategory,
  removeDuplicatedPosts,
  sortPostsByDate
} from 'utils/blog-utils';
import {BlogTitleH2, Left, PostImg} from 'components/blog/BlogComponents';
import {setMomentLocale} from 'utils/helpers';

const Article = styled.article`
  margin: 0 0 4.375rem;
  position: relative;
`;

const Info = styled.p`
  color: #acb6bb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin: 0 0 2rem;
`;

const ImageLink = styled(Link)`
  margin-bottom: 1rem;
  position: relative;
  display: block;
  background: #f3f4f6;

  img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    margin-bottom: 0;
  }
`;

const ReadMore = styled.div`
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ea7ec3;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
  }
`;

const Loading = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  color: ${colors.pink};
  margin-bottom: 2rem;
`;

const Summary = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #475157;
  margin: 0 0 2rem;

  > p {
    margin: unset;
  }
`;

const Category = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #aab4e9;
  margin: 0 0 0.5rem;
`;

type Context = {
  prismicLang: any;
  paginationNextUid: string | null;
  paginationPreviousUid?: string | null;
  uid: string;
};

const BlogCategory: React.FC<PageProps<any, Context> & {prismic: any}> = (props) => {
  const {t, language} = useI18next();
  setMomentLocale('en-gb');

  const {data, pageContext} = props;
  const [categories] = useState<BlogCategoryType[]>(
    data.prismic.categories?.edges.map(mapCategory) || []
  );

  const category = categories.find((category) => category.slug === pageContext.uid) || {
    slug: pageContext.uid,
    title: pageContext.uid,
    meta_description: pageContext.uid
  };
  const popularPosts: BlogPost[] = getPostsFromPrismicEdges({
    posts: data.prismic.popularPosts.edges,
    categories
  });
  const mustReadPosts: BlogPost[] = getPostsFromPrismicEdges({
    posts: data.prismic.mustReadPosts.edges,
    categories
  });

  const posts = sortPostsByDate(
    removeDuplicatedPosts(
      getPostsFromPrismicEdges({
        posts: [...data.prismic.categoryPosts.edges, ...data.prismic.secondaryCategoryPosts.edges],
        categories
      })
    ),
    language as BlogLocale
  );

  const filteredPosts = posts.filter((post) => post.category?.slug === category.slug);

  const [pageSize, setPageSize] = useState(10);

  const [limitedPosts, setLimitedPosts] = useState<BlogPost[]>(filteredPosts.slice(0, pageSize));

  const fetchMore = () => {
    setPageSize((value) => value + 10);
  };

  useEffect(() => {
    setLimitedPosts(filteredPosts.slice(0, pageSize));
  }, [pageSize]);

  return (
    <>
      <SEO
        path={props.path}
        title={category?.title}
        description={RichText.asText(category?.meta_description)}
        bodyAttributes={{class: 'blog'}}
      />
      <BlogLayout
        categories={categories}
        activeCategory={category?.slug}
        popularPosts={popularPosts}
        mustReadPosts={mustReadPosts}>
        <Left>
          <InfiniteScroll
            dataLength={limitedPosts.length}
            next={fetchMore}
            hasMore={filteredPosts.length > pageSize}
            loader={<Loading>Loading...</Loading>}
            children={limitedPosts.map((post: BlogPost) => {
              return (
                <Article key={post.uid!}>
                  <Category>{post.category?.title}</Category>
                  <BlogTitleH2>
                    <Link
                      to={post.slug as string}
                      title={RichText.asText(post.title)}
                      language={post.lang}>
                      {RichText.asText(post.title)}
                    </Link>
                  </BlogTitleH2>
                  <Info>
                    {post.author_card?.author_name && (
                      <Link to={`/author/${post.author_card._meta.uid}`}>
                        {RichText.asText(post.author_card.author_name)}
                      </Link>
                    )}
                    {post.author_card && ` | `}
                    {(post.updated_at ? t('Originally published') : t('Published')) +
                      ': ' +
                      post.firstPublicationDate}
                    {post.updated_at ? ' | ' + t('Updated') + ': ' + post.updated_at : ''}
                  </Info>
                  {post.imageUrl && (
                    <ImageLink
                      to={post.slug as string}
                      title={RichText.asText(post.title)}
                      language={post.lang}
                      style={{paddingTop: `${post.imageRatio}%`}}>
                      <PostImg src={post.imageUrl} alt={RichText.asText(post.title)} />
                    </ImageLink>
                  )}
                  <Summary>
                    <RichText
                      render={post.summary}
                      // @ts-ignore
                      serializeHyperlink={serializeHyperlink}
                    />
                  </Summary>
                  <ReadMore>
                    <Link
                      to={`/blog/${post.uid}/`}
                      title={RichText.asText(post.title)}
                      language={post.lang}>
                      <Trans>Read More</Trans>
                    </Link>
                  </ReadMore>
                </Article>
              );
            })}
          />
        </Left>
      </BlogLayout>
    </>
  );
};

export default BlogCategory;

export const query = graphql`
  fragment PageInfoFragment on PRISMIC_PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
  fragment PostFragment on PRISMIC_PostConnectionEdge {
    cursor
    node {
      title
      body
      image
      summary
      updated_at
      author
      category
      secondary_category
      author_card {
        ... on PRISMIC_Author {
          author_name
          _meta {
            uid
          }
        }
      }
      _meta {
        uid
        lang
        alternateLanguages {
          lang
        }
        firstPublicationDate
      }
    }
  }
  fragment CategoryFragment on PRISMIC_CategoryConnectionEdge {
    node {
      title
      meta_description
      _meta {
        uid
        lang
      }
      order
    }
  }
  query Category($uid: String, $after: String, $prismicLang: String, $first: Int = 99) {
    locales: allLocale(filter: {ns: {in: ["common", "blog"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismic: prismic {
      categories: allCategorys(lang: $prismicLang) {
        edges {
          ...CategoryFragment
        }
      }

      popularPosts: allPosts(
        sortBy: meta_lastPublicationDate_DESC
        tags: "Popular"
        first: 3
        lang: $prismicLang
      ) {
        edges {
          node {
            title
            _meta {
              uid
            }
          }
        }
      }

      mustReadPosts: allPosts(
        sortBy: meta_lastPublicationDate_DESC
        tags: "Must Read"
        first: 3
        lang: $prismicLang
      ) {
        edges {
          node {
            title
            _meta {
              uid
            }
          }
        }
      }

      categoryPosts: allPosts(
        where: {category: $uid}
        sortBy: meta_firstPublicationDate_DESC
        after: $after
        lang: $prismicLang
        first: $first
      ) {
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          ...PostFragment
        }
      }

      secondaryCategoryPosts: allPosts(
        where: {secondary_category: $uid}
        sortBy: meta_firstPublicationDate_DESC
        after: $after
        lang: $prismicLang
        first: $first
      ) {
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          ...PostFragment
        }
      }
    }
  }
`;
